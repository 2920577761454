
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next';
import { useCallback, useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Head from 'next/head';
import Layout from '../../components/Layout';
import styles from './index.module.scss';
import { useAuth } from '../../context/authContext';
import {
  createFormSchema,
  createRedirectIfNeeded,
  emailRule,
  getNoTrialTranslationKeyIfNeeded,
  passwordRule,
  Permalink,
  resetForm,
  setLoadingState,
  validateForm,
} from '../../helpers';
import { Namespace } from '../../enums/namespaces';
import { useRouter } from 'next/router';
import AppLink from '../../components/AppLink/AppLink';
import { LoginErrorType } from '../../enums/errors';
import Input from '../../components/Input';
import { basicSEO } from 'helpers/jsx';
import Analytics from 'helpers/Analytics';
import { StaticPathParams } from 'typings/staticPaths';
import { authorizeUserServerSide } from 'api/firebaseAdmin';
import { useLang } from 'context/langContext';
import LoadingComponent from '../../components/LoadingComponent';

interface Props {
  history?: string[];
  countryCode: string;
}

const Login: NextPage<Props> = ({ history, countryCode }) => {
  const auth = useAuth();
  const { t } = useTranslation(Namespace.Login);
  const ref = useRef<HTMLFormElement>(null);
  const serverError = useRef<HTMLLabelElement>(null);
  const router = useRouter();
  const { locale } = useLang();
  const [, setNotVerified] = useState<boolean>(false);

  const RULE_PASSWORD = t('common:form.password_min_chars');
  const RULE_NOT_EMPTY = t('common:form.not_empty');
  const RULE_WRONG_EMAIL = t('common:form.email_format');

  useEffect(() => {
    if (auth.isUserLoaded && auth.user) {
      router.push(Permalink.collection(locale));
    }
  }, [auth.user, auth.isUserLoaded]);

  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setLoadingState(ref.current);

      const formSchema = createFormSchema({
        email: emailRule(RULE_WRONG_EMAIL, RULE_NOT_EMPTY),
        password: passwordRule(RULE_PASSWORD, RULE_NOT_EMPTY),
      });
      const form = event.target as HTMLFormElement;
      const email = form.email.value;
      const password = form.password.value;
      const validateObject = {
        email,
        password,
      };
      const valid = validateForm(formSchema, validateObject, ref.current);
      if (valid) {
        try {
          setNotVerified(false);
          const resp = await auth.signin(email, password);
          if (!resp.emailVerified) {
            serverError.current.innerHTML = t('not_activated');
            ref.current.classList.add('display-server-errors');
            resetForm(ref.current);
            setNotVerified(true);
          } else {
            if (history.length > 0) {
              router.back();
            } else {
              router.push(Permalink.collection(locale));
            }
          }
        } catch (e) {
          resetForm(ref.current);
          if (LoginErrorType.WrongPassword === e.code || LoginErrorType.UserNotFound === e.code) {
            serverError.current.innerHTML = t('common:form.wrong_credentials');
            ref.current.classList.add('display-server-errors');
          }
        }
      } else {
        resetForm(ref.current);
      }
    },
    [auth],
  );

  return (
    <Layout countryCode={countryCode}>
      <Head>{basicSEO(t('seo:login_title'), t('seo:login_description'))}</Head>
      <main className={styles.login}>
        {auth.isUserLoaded && auth.isAnonymous ? (
          <>
            <div className={styles.loginWrapper}>
              <h1>{t('login')}</h1>
              <p>
                {t('not_register_yet')}{' '}
                <AppLink href={Permalink.registerStep1(locale)}>
                  <a>{t(getNoTrialTranslationKeyIfNeeded('try_trial', countryCode))}</a>
                </AppLink>
              </p>
              <form ref={ref} onSubmit={onSubmit}>
                <Input type="text" name="email">
                  {t('email')}
                </Input>
                <Input type="password" name="password">
                  <span className="d-flex flex-row justify-content-between">
                    {t('password')}
                    <AppLink href={Permalink.forgottenPassword(locale)}>
                      <a tabIndex={-1}>{t('forgotten_password')}</a>
                    </AppLink>
                  </span>
                </Input>
                <button type="submit" className="btn btn-primary">
                  {t('login_btn')}
                </button>
                <label ref={serverError} className="server-error" id="server-error" htmlFor="email"></label>
              </form>
            </div>
          </>
        ) : (
          <LoadingComponent spinnerSize={'medium'} />
        )}
      </main>
    </Layout>
  );
};

 async function _getServerSideProps(
  ctx: GetServerSidePropsContext<StaticPathParams>,
): Promise<GetServerSidePropsResult<Props>> {
  const { user, locale, apiClient, countryCode } = await authorizeUserServerSide(ctx);

  const redirect = createRedirectIfNeeded(ctx, locale);
  if (redirect) {
    return redirect;
  }

  if (user) {
    return {
      redirect: {
        permanent: false,
        destination: Permalink.collection(locale),
      },
    };
  }

  return {
    props: { countryCode },
  };
}

export default Login;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  children?: React.ReactNode;
  labelClass?: string;
}

const Input = ({ children, labelClass, ...props }: Props): JSX.Element => {
  return (
    <label className={labelClass}>
      {children}
      <input {...props} />
      <span></span>
    </label>
  );
};

export default Input;
